<template>
    <div class="dialog-box">
        <div class="close" @click="handleClose">
            <i class="iconfont iconguanbi"></i>
        </div>
        <div class="content_box">
            <div class="content">
                <div class="head_go_name">
                    发送给：
                    <img v-if="chatdata.url" src="../../../../images/icon_z.png" alt="">
                    <p v-else :style="`background: ${chatdata.User_Gender === 0 ? '#fe86a8' : '#4da9ff'}`">
                        {{ chatdata.name }}
                    </p>
                </div>
                <div class="file_box">
                    <div class="file_item" v-for="(item, i) in files" :key="i">
                        <div class="left">
                            <i class="iconfont" :class="item.icon"></i>
                            <!-- <i class="iconfont icontxt"></i>
                            <i class="iconfont iconexcel"></i>
                            <i class="iconfont iconyasuowendang"></i> -->
                        </div>
                        <div class="right">
                            <p>{{ item.name }}</p>
                            <p>{{ Math.round(item.size / 1024 * 10) / 10 + 'kb' }}</p>
                        </div>
                    </div>
                </div>
                <div class="message_box">
                    <div class="title">
                        给朋友留言：
                    </div>
                    <div class="inp">
                        <el-input
                            type="textarea"
                            :rows="6"
                            placeholder="请输入留言"
                            v-model="value"
                        >
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="btns">
                <el-button class="btn btn2" @click="handleClose">
                    取消
                </el-button>
                <el-button
                    class="btn btn1"
                    @click="saveFun"
                >
                    确认
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { matchType } from '@/views/messageCenter/js/matchType.js';
export default {
    name: 'sys-update',
    data() {
        return {
            files: [],
            chatdata: {},
            value: '',
        };
    },
    props: {
        extr: Object,
    },
    created() {
        if (this.extr) {
            this.chatdata = this.extr.chatdata;
            this.files = this.extr.files;
            this.files.forEach(item => {
                item.icon = matchType(item.name).icon;
            });
        }
    },
    methods: {
        handleClose() {
            this.extr.clearFun();
            this.$parent.hide();
        },
        saveFun() {
            this.extr.saveFun(this.value);
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.dialog-box
    position: relative;
    z-index: 11;
    width 100%;
    height 100%
    .close
        position absolute;
        right 0.1rem;
        top 0.1rem;
        width: 0.25rem;
        height: 0.25rem;
        padding-top 0.01rem;
        display flex;
        justify-content center;
        align-items center;
        cursor pointer;
        .iconguanbi
            font-size 0.14rem;
            color #000;
    .content_box
        box-sizing border-box
        width 100%;
        height 100%
        padding 0.2rem 0.4rem;
        background #fff;
        .content
            height calc(100% - .6rem)
            overflow auto
            .head_go_name
                border-bottom 1px solid #e5e5e5
                margin-bottom .1rem
                display flex
                line-height .5rem
                img
                    width .4rem
                    height .4rem
                p
                    width: .40rem;
                    height: .40rem;
                    border-radius: 2px;
                    background: #15a9fe;
                    color: #fff;
                    font: .14rem/.40rem "微软雅黑";
                    text-align: center;
            .file_box
                max-height 3rem
                padding .1rem
                overflow auto
                margin-bottom .2rem
                .file_item
                    height .8rem
                    display flex
                    .left
                        width .5rem
                        margin 0 .15rem .15rem 0
                        background #F7F7F7
                        i
                            font-size .45rem
                    .right
                        flex 1
                        p
                            font-size: 14px;
                            font-family: SourceHanSansCN, SourceHanSansCN;
                            font-weight: 500;
                            color: #333333;
                            line-height: .32rem;
            .message_box
                height 2rem
                .title
                    color #999999
                    font-size .18rem
                    margin-bottom .1rem
        .btns
            height .6rem
            display flex;
            justify-content space-around;
            align-items center;
            position absolute
            bottom 0
            left 50%
            transform translate(-50%)
            .btn
                display flex
                justify-content center;
                align-items center;
                width: 1.4rem;
                height: 0.4rem;
                border-radius: 1px;
                border: 1px solid #1577D2;
                color #1577D2;
                font-size 0.16rem;
                &.btn1
                    background: #1577D2;
                    border-radius: 1px;
                    color #fff;
</style>
